<template>
  <div :class="`${isMobile ? 'h-100' : 'row w-100 h-100'}`">
    <div class="col-sm left-div" v-if="!isMobile" />
    <div :class="`${isMobile ? 'mt-10 mx-2' : 'col-sm d-flex flex-column justify-content-center'}`">
      <div :class="`${isMobile ? 'w-100' : 'w-75 mx-auto'} p-5 box-white center`">
        <span class="font-25 font-bold">{{ $t('select_account_type') }}</span>
        <v-btn class="w-100 mt-10 button-normal" @click="selectUserType(0)">
          <span>{{ $t('normal_user') }}</span>
        </v-btn>
        <v-btn class="w-100 my-5 button-normal" @click="selectUserType(1)">
          <span>{{ $t('business_user') }}</span>
        </v-btn>
      </div>
      <TermsComponent></TermsComponent>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import { getLocalStorageInfo } from '../../../functions';

import TermsComponent from "@/view/pages/auth/TermsComponent.vue";

export default {
  name: 'SelectUserType',
  components: {
    TermsComponent
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  methods: {
    selectUserType(userType) {
      let redirect;
      const userInfo = getLocalStorageInfo('eventboxRegisterUser');
      if (userInfo && userInfo.email) {
        userInfo['userType'] = userType;
        localStorage.setItem('eventboxRegisterUser', JSON.stringify(userInfo));
        redirect = 'register';
      } else {
        localStorage.setItem('eventboxUserType', userType);
        redirect = 'welcome';
      }
      if (userType === this.USER_ORGANIZER) {
        this.$router.push(`/business_agreement/${redirect}`);
      } else if (userType === this.USER_NORMAL) {
        this.$router.push(`/${redirect}`);
      }
    }
  }
};
</script>